import React from "react";
import { Bounce, Fade } from "react-reveal";
import "./Projects.css";

export default function Projects() {
  return (
    <section className="projects" id="projects">
      <div className="container">
        <div className="section-title">
          <h2>Projects by Electrixitaty</h2>
        </div>
        <div className="row d-flex justify-content-around ">
          <Fade left>
            <div
              onClick={() => {
                window.open("https://www.gokijany.electrixitaty.co", "_blank");
              }}
              className="project col-lg-4"
            >
              <div className="project-1"></div>
              <h2>GOKIJANY</h2>
            </div>
          </Fade>
          <Bounce>
            <div
              onClick={() => {
                window.open(
                  "https://www.coderhythm.electrixitaty.co",
                  "_blank"
                );
              }}
              className="project col-lg-4"
            >
              <div className="project-1"></div>
              <h2>CODE RHYTHM</h2>
            </div>
          </Bounce>
          <Fade right>
            <div
              onClick={() => {
                window.open("https://www.dripup.electrixitaty.co", "_blank");
              }}
              className="project col-lg-4"
            >
              <div className="project-1"></div>
              <h2>DRIPUP</h2>
            </div>
          </Fade>
          <Fade left>
            <div
              onClick={() => {
                window.open(
                  "https://www.wansenziroz.electrixitaty.co",
                  "_blank"
                );
              }}
              className="project col-lg-4"
            >
              <div className="project-1"></div>
              <h2>WANSENZIROZ</h2>
            </div>
          </Fade>
          <Bounce>
            <div
              onClick={() => {
                window.open("https://www.veezavyz.electrixitaty.co", "_blank");
              }}
              className="project col-lg-4"
            >
              <div className="project-1"></div>
              <h2>VEEZAVYZ</h2>
            </div>
          </Bounce>
          <Fade right>
            <div
              onClick={() => {
                window.open("https://www.ingonyama.electrixitaty.co", "_blank");
              }}
              className="project col-lg-4"
            >
              <div className="project-1"></div>
              <h2>INGONYAMA</h2>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
}
