import { Link } from "react-router-dom";
import "./Header.css";
export default function Header({ scrollToSection, isLogoVisible }) {
  return (
    <header
      className="header"
      style={
        isLogoVisible
          ? {
              backgroundColor: "#fff",
              borderBottomLeftRadius: 20,
              borderBottomRightRadius: 20,
            }
          : { backgroundColor: "#fff", justifyContent: "space-evenly" }
      }
    >
      <Link className="link" to={"/"}>
        <h1
          className="logo"
          style={isLogoVisible ? { width: `15vw` } : { width: 0 }}
        ></h1>
      </Link>

      <nav
        style={
          !isLogoVisible
            ? { width: "80vw", justifyContent: "space-between" }
            : { width: "70vw", justifyContent: "space-evenly" }
        }
      >
        <div
          onClick={() => scrollToSection("hero")}
          className="nav-option link"
        >
          Home
        </div>
        <div
          onClick={() => scrollToSection("about")}
          className="nav-option link"
        >
          About Us
        </div>
        <div
          onClick={() => scrollToSection("projects")}
          className="nav-option link"
        >
          Projects
        </div>
        <a
          className="nav-option link"
          href={"https://lumona.electrixitaty.co"}
          // target={"_blank"}
          rel="norefere"
        >
          The Founder
        </a>
        <div
          onClick={() => scrollToSection("contact")}
          className="nav-option link"
        >
          Get In Touch
        </div>
      </nav>
    </header>
  );
}
