import "./Hero.css";
import hero1 from "../../../../images/hero-img-1.svg";
import hero2 from "../../../../images/hero-img-2.svg";
import herocube from "../../../../images/hero-cube.png";
import { useEffect, useState } from "react";
import { Rotate } from "react-reveal";
export default function Hero({ isLogoVisible, isIntroInfo }) {
  const [isShortWhiteBg, setIsShortWhiteBg] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsShortWhiteBg(true);
    } else {
      setIsShortWhiteBg(false);
    }
  }, []);
  return (
    <section className="hero" id="hero">
      <div
        className="overlay"
        style={isLogoVisible ? { opacity: 1 } : { opacity: 0 }}
      ></div>
      {/* <Fade down> */}
      <div
        className="white-bg"
        style={
          isLogoVisible
            ? { height: 0 }
            : { height: isShortWhiteBg ? "20vh" : "45vh" }
        }
      >
        <h1
          style={
            isLogoVisible
              ? {
                  position: "fixed",
                  fontSize: 26,
                  top: 10,
                  left: 20,
                  display: "block",
                  textAlign: "left",
                }
              : {
                  top: 0,
                  left: 0,
                  position: "relative",
                  textAlign: "center",
                  backgroundColor: "#fff",
                }
          }
        >
          ELECTRIXITATY.
        </h1>

        {/* <img
          style={
            {
              // top: `${heroTitleTop}%`,
              // left: `${heroTitleLeft}%`,
              // transform: `translate(${heroTransformX}%, ${heroTransformY}%)`,
            }
          }
          src={logo}
          alt="Electrixitaty-logo"
        /> */}
      </div>
      {/* </Fade> */}

      <div className="container ">
        <div className="hero-bottom d-flex justify-content-between align-items-center row">
          <div
            className="hero-info col-lg-4"
            style={!isLogoVisible ? { opacity: 1 } : { opacity: 0 }}
          >
            <img src={hero1} alt="electrixitaty mission" className="hero-img" />
            <p>
              Electrixitaty is a pioneering technology company dedicated to
              driving innovation and fostering sustainable development in Kenya
              through cutting-edge projects and initiatives.
            </p>
          </div>

          <Rotate>
            <div className="hero-cube">
              <img
                src={herocube}
                alt="hero-cube"
                style={!isIntroInfo ? { opacity: 1 } : { opacity: 0 }}
              />
            </div>
          </Rotate>

          <div
            className="hero-info col-lg-4"
            style={!isLogoVisible ? { opacity: 1 } : { opacity: 0 }}
          >
            <img src={hero2} alt="electrixitaty mission" className="hero-img" />

            <p>
              With a focus on empowering individuals and communities through
              technology, Electrixitaty leverages its expertise to create
              impactful solutions that address pressing societal and
              environmental challenges, shaping a brighter future for all.
            </p>
          </div>
        </div>
        <div
          className="intro-info"
          style={isIntroInfo ? { opacity: 1 } : { opacity: 0 }}
        >
          <h2>Welcome to Electrixitaty!</h2>
          <p>
            We are on a mission to inspire and educate young Kenyans about the
            transformative power of technology for a better future. Explore our
            innovative projects and initiatives aimed at promoting
            sustainability, tech education, digital shopping experiences,
            inclusive communication, intelligent security systems, and more.
            Join us in shaping a brighter tomorrow!
          </p>
        </div>

        <div className="row d-flex justify-content-between align-items-center">
          {/* <div className="hero-left "> hero</div>
          <div className="hero-right "></div> */}
        </div>
      </div>
    </section>
  );
}
