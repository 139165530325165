import "./Contact.css";
import { Bounce, Fade, Zoom } from "react-reveal";
import Swal from "sweetalert2";

import emailjs from "emailjs-com";
import { useState } from "react";

export default function Contact() {
  const [formData, setFormData] = useState({
    firstName: "",
    secondName: "",
    email: "",
    subject: "",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    setIsLoading(true); // Set loading state

    // Show loading alert
    Swal.fire({
      title: "Sending...",
      text: "Please wait while we send your message.",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading(); // Show loading spinner
      },
    });

    // Send email
    emailjs
      .send(
        "service_u25trcr",
        "template_gl9cz2f",
        formData,
        "GkZDgimhm2fQr7ioU"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setFormData({
            // Reset form fields
            fullname: "",
            email: "",
            subject: "",
            message: "",
          });
          setIsLoading(false); // Reset loading state

          // Show success alert
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Message sent successfully!",
          });
        },
        (err) => {
          console.log("FAILED...", err);
          setIsLoading(false); // Reset loading state

          // Show error alert
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong while sending the message!",
          });
        }
      );
  };
  return (
    <section id="contact" className="contact section">
      <div className="container section-title" data-aos="fade-up">
        <h2 className="">Get In Touch</h2>
      </div>

      <div className="container" data-aos="fade-up" data-aos-delay="100">
        <div className="row gy-4">
          <Bounce>
            <div className="col-lg-6">
              <div
                className="info-item d-flex flex-column justify-content-center align-items-center"
                data-aos="fade-up"
                data-aos-delay="200"
              >
                <i className="bi bi-geo-alt"></i>
                <h3>Address</h3>
                <p>CBD, Nairobi, Kenya</p>
              </div>
            </div>
          </Bounce>

          <Zoom>
            <div className="col-lg-3 col-md-6">
              <div
                className="info-item d-flex flex-column justify-content-center align-items-center"
                data-aos="fade-up"
                data-aos-delay="300"
              >
                <i className="bi bi-telephone"></i>
                <h3>Call Us</h3>
                <p>+254 705 247 958</p>
              </div>
            </div>
          </Zoom>

          <Fade left>
            <div className="col-lg-3 col-md-6">
              <div
                className="info-item d-flex flex-column justify-content-center align-items-center"
                data-aos="fade-up"
                data-aos-delay="400"
              >
                <i className="bi bi-envelope"></i>
                <h3>Email Us</h3>
                <p>info@electrixitaty.co</p>
              </div>
            </div>
          </Fade>
        </div>

        <div className="row gy-4 mt-1">
          <div className="col-lg-12">
            <form
              action="forms/contact.php"
              method="post"
              className="php-email-form"
              data-aos="fade-up"
              data-aos-delay="400"
              onSubmit={handleSubmit}
            >
              <div className="row gy-4">
                <Fade right>
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="name"
                      className="form-control"
                      placeholder="Your Name"
                      required=""
                      value={formData.fullname}
                      onChange={handleChange}
                    />
                  </div>
                </Fade>

                <Fade up>
                  <div className="col-md-6 ">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      placeholder="Your Email"
                      required=""
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </Fade>
                <Fade down>
                  <div className="col-md-12">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      placeholder="Subject"
                      required=""
                      value={formData.subject}
                      onChange={handleChange}
                    />
                  </div>
                </Fade>

                <Bounce>
                  <div className="col-md-12 ">
                    <textarea
                      className="form-control"
                      name="message"
                      rows="6"
                      placeholder="Message"
                      required=""
                      value={formData.message}
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </Bounce>
                <button className="w-25" type="submit" disabled={isLoading}>
                  {isLoading ? "Sending..." : "Send Message"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
